import { render, staticRenderFns } from "./commerpro.vue?vue&type=template&id=be34f14c&scoped=true"
import script from "./commerpro.vue?vue&type=script&lang=js"
export * from "./commerpro.vue?vue&type=script&lang=js"
import style0 from "./commerpro.vue?vue&type=style&index=0&id=be34f14c&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be34f14c",
  null
  
)

component.options.__file = "commerpro.vue"
export default component.exports